

.navbar {
  display: flex;
  justify-content: center;
  position: relative;
  width: 280px;
  background-color: #1D1D41;
  border-radius: 25px;
  color: #fff;
  user-select: none;
  &__wrapper {
    position: fixed;
    max-width: 280px;
    padding: 40px 30px;
    display: block ;
    margin: 0 auto;
  }
  &__logo {
    display: flex;
    align-items: center;
    position: relative;
    &-fire {
      margin-right: 10px;
    }
    &-logo {
      display: block;
      margin-top: 12px;
      font-size: 30px;
      font-weight: 700;
      color: #fff;
    }
  }
  &__nav {
    width: 90%;
    margin: 0 auto;
    margin-top: 50px;
    
  }
  
  &__divider {
    display: block;
    height: 2px;
    background-color: #4B4B99;
  }
  &__item {
    display: flex;
    align-items: center;
    width: 100%;
    height: 57px;
    border-radius: 10px;
    margin: 5px 0;
    padding-left: 15px;
    cursor: pointer;

    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    span {
      margin-left: 12px;
      margin-top: 9px;
    }
  }
  &__item-active {
    background: #6359E9;
  }

  .hiden {
    display: none !important;
  }

  .hamburger {
    display: none;
    background-color: rgba($color: #000000, $alpha: 0);
    border: none;
    height: 60px;
    img {
      height: 100%;
    }
  }

  .navbar__logo_mobile {
    display: none;
  }
}