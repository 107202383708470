

.main {
  display: block;
  min-height: 100vh; 
  padding: 50px 70px;
  
  background: url(../../assets/img/bg/wall1.png) center center / cover no-repeat;
  &__container {
    position: relative;
    max-width: 1540px;
    margin: 0 auto;
    display: flex;
    background: linear-gradient(333deg, rgba(20,19,50,0.6) 11%, rgba(99,89,233,0.9) 100%);
    border-radius: 25px;
  }
  &__wrapper {
    min-height: 100vh;
    width: 1260px;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    
  }
  &__wrapper_top {
    display: flex;
    justify-content: space-around;
  }
  &__content {
    width: 57%;
  }
  &__content_right {
    width: 38%;
  }

  
  
  
  


  
}

