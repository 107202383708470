
.user-block {
  padding: 20px;
  background: #1D1D41;
  color: #fff;
  border-radius: 20px;
  margin-bottom: 30px;
  h2 {
    font-size: 28px;
  }
  &__profile {
    margin-bottom: 20px;
    margin-left: 20px;
  }
  &__wrapper {
    padding: 20px;
    background-color: #29295C;
    border-radius: 20px
  }
  &__img-and-name {
    position: relative;
    display: flex;
    margin-bottom: 20px;
    margin-left: 20px;
  }
  &__user-icon {
    height: 100px;
    width: 100px;
    border-radius: 100%;
    margin-right: 15px;
  }
  &__user-icon-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__user-icon-change {
    height: 30px;
  }
  input {
    display: none;
  }
  label {
    position: absolute;
    top: 65px;
    height: 30px;
    // left: 105px;
    background-color: #29295C;
    border-radius: 6px;
    width: 30px;
    cursor: pointer;
  }
  h3 {
    margin-bottom: 15px;
    font-size: 24px;
  }
  &__text {
    font-size: 18px;
    margin-bottom: 5px;
  }
  
}