

.sign-out {
  button {
    position: absolute;
    right: 0px;
    height: 50px;
    border: none;
    // background-color: rgba($color: #000000, $alpha: 0);
    background-color: #29295C;
    border-radius: 6px;
    img {
      height: 100%;
      cursor: pointer;
    }
  }
}
