


.analytics {
  padding: 20px;
  background: #1D1D41;
  color: #fff;
  border-radius: 20px;
  margin-bottom: 30px;
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  &__header_right {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
  &__label {
    width: 150px;
    display: block;
    margin: 0 auto;
    margin-top: 15px;
  }
  .circle {
    display: block;
    width: 12px;
    height: 12px;
    margin-right: 10px;
    margin-left: 20px;
    border-radius: 100%;
    background: #6359E9;
  }
  .income {
    background: #64CFF6;
  }
  img {
    width: 100px;
  }
}