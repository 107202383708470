
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

* {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // overflow: hidden; 
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-wrapper {
  position: relative;
}
.error {
  color: red;
  margin-left: 2px;
  margin-top: -10px;
  font-size: 14px;
  position: absolute;
}
.error__sign-up {
  margin-top: -28px;
}
.error-for-sign-in {
  font-size: 16px;
  top: 338px;
}

p {
  margin: 0;
  padding: 0;
}