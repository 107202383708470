


.funds {
  padding: 20px;
  width: 100%;
  background: #1D1D41;
  color: #fff;
  border-radius: 20px;
  &__header {
    margin-bottom: 20px;
    margin-left: 20px;
  }
  &__divider {
    display: block;
    width: 120px;
    height: 3px;
    background-color: #fff;
    margin-bottom: 15px;
  }

  input {
    display: block;
    height: 40px;
    background-color:rgba(21,21,21,0);
    border: none;
    border-bottom: 3px solid #4D62F8;
    font-size: 16px;
    color: #fff;
    width: calc(100% - 110px);
  }
  input:focus {
    outline: none;
  }
  input::placeholder {
    color: #fff;
  }
  &__add {
    padding: 20px;
    background-color: #29295C;
    border-radius: 20px;
    margin-bottom: 20px;
  }
  &__funds-wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  &__funds-button {
    cursor: pointer;
    height: 40px;
    padding: 5px;
    background-color: #1D1D41;
    color: #fff;
    font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;

    border-radius: 8px;
    // border-color: aqua;
    // border-top-left-radius: 8px;
    // border-top-right-radius: 8px;
    border: none;
    // border-bottom: 3px solid #8A38F4;
    color: #fff;
    
  }
  .error__add {
    bottom: -17px;
  }
  &__funds-button:hover {
    transition: all 0.4s;
    box-shadow: inset 0 -3.25em 0 0 #4D62F8;
  }

  &__transaction {
    padding: 20px;
    background-color: #29295C;
    border-radius: 20px;
    input {
      margin-bottom: 10px;
      width: 100%;
    }
    button {
      margin-top: 10px;
    }
  }
  &__transaction-wrapper {
    display: flex;
    flex-direction: column;
    
  }
}